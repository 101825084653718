@import "_cstm-variable.scss";
/*------------- color variables ------------*/

@function color($color-name) {
  @return map-get($colors, $color-name);
}
/*------------- color variables ------------*/

/*----------------- responsive variations -----------------*/
$xl-screen: 1500px;
$max-screen: 1440px;
$large-screen: 1400px;
$mini-screen: 1200px;
$tab-max: 1032px;
$ipad-view: 991px;
$tab-view: 767px;
$mobile-view: 558px;
$mobile-md-view: 530px;
$mobile-sm-view: 480px;
$mobile-small-view: 410px;
$mobile-xsm-view: 370px;
$mobile-custom-view: 390px;
$mobile-xxsm-view: 350px;

@mixin max-screen-view {
  @media (max-width: #{$max-screen}) {
    @content;
  }
}

@mixin xl-view {
  @media (min-width: #{$xl-screen}) {
    @content;
  }
}

@mixin mobile-custom-view {
  @media (max-width: #{$mobile-custom-view}) {
    @content;
  }
}

@mixin mobile-small-view {
  @media (max-width: #{$mobile-small-view}) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: #{$large-screen}) {
    @content;
  }
}

@mixin large-screen-max {
  @media (max-width: #{$large-screen}) {
    @content;
  }
}

@mixin mini-screen {
  @media (max-width: #{$mini-screen}) {
    @content;
  }
}
@mixin mini-view-screen {
  @media (min-width: #{$mini-screen}) {
    @content;
  }
}
@mixin tab-max {
  @media (max-width: #{$tab-max}) {
    @content;
  }
}
@mixin tab-min {
  @media (min-width: #{$tab-max}) {
    @content;
  }
}
@mixin tab-view-min {
  @media (min-width: #{$tab-view}) {
    @content;
  }
}
@mixin ipad-view {
  @media (max-width: #{$ipad-view}) {
    @content;
  }
}
@mixin tab-view {
  @media (max-width: #{$tab-view}) {
    @content;
  }
}
@mixin mobile-view {
  @media (max-width: #{$mobile-view}) {
    @content;
  }
}
@mixin mobile-md-view {
  @media (max-width: #{$mobile-md-view}) {
    @content;
  }
}
@mixin mobile-sm-view {
  @media (max-width: #{$mobile-sm-view}) {
    @content;
  }
}
@mixin mobile-xsm-view {
  @media (max-width: #{$mobile-xsm-view}) {
    @content;
  }
}
@mixin mobile-xxsm-view {
  @media (max-width: #{$mobile-xxsm-view}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$ipad-view}) {
    @content;
  }
}
/*----------------- responsive variations -----------------*/
@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin flexcolumn {
  display: flex;
  flex-direction: column;
}

@mixin flexAllCenter {
  @include flexAlignCenter;
  justify-content: center;
}

@mixin flexSpacebetween {
  @include flexAlignCenter;
  justify-content: space-between;
}

@mixin btnReset {
  background-color: transparent;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
