@import "_cstm-variable.scss";
@import "../_cstm-mixins.scss";
.search_result {
  width: 100%;
  padding: 25px 0 40px;
  .price_by_date {
    width: 100%;
    margin: 0 0 30px;

    @include ipad-view {
      margin-top: 10px;
    }

    label {
      font-size: 16px;
      font-weight: 500;
      color: $text-grey;
      margin: 0 0 5px;
      display: flex;
      justify-content: space-between;
      span {
        &.pink_txt {
          color: $primary;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            background: $primary;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }

    
  }

  .result_listing {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      justify-content: space-between;
    }
  }
}

.address_ng {
  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        .ng-arrow {
          border: none;
          background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_up_dropdown.svg);
          background-position: right;
          background-repeat: no-repeat;
          background-size: 11px;
          transition: 0.3s;
          width: 12px !important;
          height: 10px !important;
        }
      }
    }
  }
  &.ng-select-disabled {
    .ng-select-container {
      background-color: $text-white;
    }
  }
  .ng-select-container {
    border: none;
    background-color: $text-white;
    &:hover {
      box-shadow: none;
      outline: none;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    .ng-value-container {
      padding-left: 0;
      @include tab-view {
        top: -2px;
        position: relative;
      }
      .ng-placeholder {
        font-size: 16px;
        color: $mid_gray_bg;
        @include tab-view {
          font-size: 14px;
          top: -4px;
          position: relative;
        }
      }
      .ng-value {
        @include tab-view {
          top: -3px;
          position: relative;
        }
        .ng-value-label {
          font-size: 16px;
          color: $mid_gray_bg;
          @include tab-view {
            font-size: 14px;
          }
        }
      }
      .ng-input {
        padding: 0 !important;
        @include tab-view {
          top: -6px !important;
        }
      }
    }
    .ng-clear-wrapper {
      @include tab-view {
        top: -4px;
      }
    }
    .ng-arrow-wrapper {
      @include tab-view {
        top: -6px;
      }
      .ng-arrow {
        border: none;
        background-image: url(https://d2q1prebf1m2s9.cloudfront.net/assets/images/arrow_dropdown.svg);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 11px;
        transition: 0.3s;
        width: 12px !important;
        height: 10px !important;
      }
    }
  }
  &.address_ng2 {
    .ng-select-container {
      @include tab-view {
        top: -7px;
      }
    }
  }
}

//<!-- Start Facebook Loader css -->
.fb_animation {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  /* animation-name: placeHolderShimmer; */
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: $fb-loader-bg;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    8%,
    $fb-loader-colorone,
    18%,
    $fb-loader-colortwo,
    33%,
    $fb-loader-colorone
  );
  background: linear-gradient(
    to right,
    $fb-loader-colorone 8%,
    $fb-loader-colortwo 18%,
    $fb-loader-colorone 33%
  );
  background-size: 800px 104px;
  min-height: 18px;
  position: relative;
  height: 100%;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

//<!-- Close Facebook Loader css -->
//<!-- Start page loder popup css -->
.page_load {
  width: 100%;
  height: 100%;
  position: fixed;
  background: $bg-black-opacity-half;
  top: 0;
  left: 0;
  z-index: 99999;
  .page_load_inner {
    width: 600px;
    max-width: 96%;
    float: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $text-white;
    padding: 50px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 3px 20px $shadow-black-light;
    @media (max-width: 767px) {
      padding: 15px;
    }
    .page_loading {
      width: 100%;
      .block_cls {
        position: absolute;
        top: 15px;
        right: 15px;
        .close_btn {
          padding: 0;
          line-height: normal;
          background: transparent;
          font-size: 0;
          &:before {
            display: none;
          }
          img {
            margin: 0;
            height: auto;
          }
        }
      }
      .block_in {
        .block_loader {
          .animation_player {
            height: 150px;
            margin: 0 0 30px;
            @media (max-width: 767px) {
              height: 100px;
            }
          }
        }
      }
      h6 {
        font-size: 24px;
        color: $secondary-color;
        margin: 0 0 15px;
        span {
          color: $text-darkblue;
        }
      }
      p {
        font-size: 15px;
        color: $text-grey;
        margin: 30px 0 0;
      }
      .ui-progressbar {
        position: relative;
        text-align: center;
        border-radius: 10px;
        border: none;
        height: 20px;
        background: $bg-load_grey;
        .progress-label {
          display: none;
        }
      }
      .ui-widget-header {
        background: $primary;
      }
    }
  }
}

//<!-- Close page loder popup css -->
//<!-- Start No Result Found page css -->
.block_noresult_found {
  width: 100%;
  padding: 50px;
  background: $text-white;
  border-radius: 5px;
  box-shadow: 3px 4px 10px $shadow-color;
  .block_noresult_found_inner {
    width: 550px;
    max-width: 96%;
    margin: 0 auto;
    text-align: center;
    img {
      max-width: 100%;
    }
    h6 {
      font-size: 20px;
      font-weight: 600;
      color: $text-black;
      margin: 15px 0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: $text-grey;
      margin: 0;
    }
  }
}


//<!-- Close Send Ticket Modal css -->
//<!-- Start No Result Found page css -->
.block_booking_failed {
  width: 100%;
  padding: 50px;
  background: $text-white;
  border-radius: 5px;
  box-shadow: 3px 4px 10px $shadow-color;
  @media (max-width: 767px) {
    padding: 30px;
  }
  .block_booking_failed_inner {
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
    }
    h6 {
      font-size: 40px;
      font-weight: 700;
      color: $text-black;
      margin: 30px 0 20px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 34px;
      }
      @media (max-width: 575px) {
        font-size: 26px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: $text_grey_dark;
      margin: 0 0 35px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 14px;
      }
      a {
        color: $text-lightblue;
      }
    }
  }
}